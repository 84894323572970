<template>
  <div>
    <Header />
    <div class="bg-mid-grey mt-5">
      <div class="grid-container">
        <div class="grid-x grid-padding-x">
          <div class="cell small-12 medium-4 pt-5 pb-5">
            <h1 class="page-header">Population</h1>
          </div>
          <div class="cell small-12 medium-2 medium-offset-4 pt-5 pb-5">
            <router-link to="/population">
                <button class="button expanded blue-ghost">Select</button>
            </router-link>
          </div>
          <div class="cell small-12 medium-2 pt-5 pb-5">
            <button class="button expanded blue-active">Input manually</button>
          </div>
        </div>
      </div>
    </div>
    <div class="bg-light-grey">
      <div class="grid-container">
        <div class="grid-x grid-padding-x">
          <div class="cell small-12 pt-5 pb-5">
            <h5>
                If you would like to review budget impact at a hospital level, enter the name of
                the hospital and the population it serves below.
            </h5>
          </div>
        </div>
        <div class="grid-x grid-padding-x">
          <div class="cell small-12 pt-5 pb-5">
            <label>If modelling at a hospital level insert name:</label>
            <input v-model="hospital" type="text" class="wide" placeholder="Insert the name of the hospital you wish to model">
          </div>
        </div>
        <div class="grid-x grid-padding-x">
          <div class="cell small-12 pt-5">
            <label>Population covered by the NHS hospital trust:</label>
            <number v-number="number" v-model="population" type="text" class="wide" placeholder="Insert the size of the population that the hospital serves"></number>
          </div>
        </div>
        <div class="grid-x grid-padding-x pt-5 pb-5">
          <div class="cell small-12 medium-2 medium-offset-10 text-right">
            <button
                v-if="population.length > 0"
                @click="setPopulation"
                class="button">Next</button>
            <button
                v-else
                class="button  disabled">Next</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from '@/components/Header.vue';

export default {
  name: 'PopulationManual',
  components: {
    Header,
  },
  computed: {
    hash() {
      return this.$store.state.hash;
    },
    population: {
      get() {
        return this.$store.state.population;
      },
      set(value) {
        this.$store.commit('setPopulation', value);
      },
    },
    hospital: {
      get() {
        return this.$store.state.hospital;
      },
      set(value) {
        this.$store.commit('setHospital', value);
      },
    },
  },
  data() {
    return {
      number: {
        separator: ',',
        precision: 2,
        masked: true,
      },
    };
  },
  methods: {
    setPopulation() {
      this.$router.push('/drug-timeframe');
    },
    sendPage() {
      this.$store.commit('setPage', 'population');
    },
    checkHashExists() {
      if (this.hash.length === 0) {
        this.$router.push('/inactive-link');
      }
    },
    checkPopulationType() {
      if (this.$store.state.manualPopulation === false) {
        this.$store.commit('setPopulation', 0);
        this.$store.commit('setPopulationType', true);
      }
    },
  },
  mounted() {
    this.checkPopulationType();
    this.checkHashExists();
    this.sendPage();
  },
};
</script>
